<script>
import NavBarLeft from "@/components/NavBarLeft.vue";
export default {
  name: "error",
  components: {
    NavBarLeft
  }
}
</script>

<template>
  <div>
    <div class="layout-wrapper d-lg-flex">
      <NavBarLeft />
      <div class="w-100">
        <div class="m-auto col-md-8 col-lg-6 col-xl-5">
          <div class="card" style="margin-top: 120px;">
            <div class="card-body p-4">
              <div class="text-center px-4">
                <h1>SOMETHING WENT WRONG...</h1>
              </div>
              <div class="text-center pt-4">
                <p>An error has occurred while processing your order. Please check that the details you have entered are correct and try again.</p>
                <router-link to="/checkout" class="btn btn-primary">Try Again</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>